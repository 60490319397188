import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios'
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '34ch',
    },
  },
}));

export default function MultilineTextFields() {
  const classes = useStyles();


  const submitform=(e)=>{

    e.preventDefault()

    const fname = document.getElementById("Firstname").value
    const lname = document.getElementById("lastname").value
    const email = document.getElementById("Email").value
    const contact = document.getElementById("Contact").value
    const message = document.getElementById("message").value


    const uploading = async() => {
      await axios.post("http://localhost:3001/api/message/sender/", {
                        
              fname:fname,
              lname:lname,
              email:email,
              contact:contact,
              message:message

                      }).then(res => {

   

      })

  }


  }
  

  return (
    <form onSubmit={submitform} className={classes.root} noValidate autoComplete="off">
     
     
      <div>
        
        <TextField
          id="Firstname"
          label="First Name"
          placeholder="First Name"
          multiline
          variant="outlined"
        />
        <TextField
          id="lastname"
          label="Last Name"
          placeholder="Last Name"
          multiline
          variant="outlined"
        />
       </div> 
      <div>
        <TextField
          id="Email"
          label="Email"
          placeholder="Email"
          multiline
          variant="outlined"
        type="Email"
        />
        <TextField
          id="Contact"
          label="Contact"
          placeholder="Contact"
          multiline
          variant="outlined"
       type="tel"
        />
        <TextField
          id="message"
          label="Message"
          multiline
          rows={4}
           placeholder="Enter Message"
          variant="outlined"
      style={{width:"97%"}}
        />
      </div>
      <div align="center">
      <Button type="submit" variant="contained" color="success">
 Send Message
</Button>
      </div>
     
    </form>
  );
}


