import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import Img1 from './img/trip/2022.jpg';
import Img2 from './img/trip/2022_0.jpg';
import Img3 from './img/trip/2022_1.jpg';
import Img4 from './img/trip/2022_2.jpg';
import Img5 from './img/trip/mad1.jpg';
import Img7 from './img/trip/mad2.jpg';
import Img9 from './img/trip/comgh1.jpg';
import Img10 from './img/trip/give1.jpg';
import Img11 from './img/trip/give2.jpg';
import Img12 from './img/trip/give3.jpg';
import Img13 from './img/trip/kenya.jpg';
import Img14 from './img/trip/kenya1.jpg';
import Img15 from './img/trip/ethio1.jpg';
import Img16 from './img/trip/ethio2.jpg';
import Img17 from './img/trip/ethio3.jpg';
import Img18 from './img/trip/ethio4.jpg';
import Img19 from './img/FB_IMG_1689787670548.jpg';
import Img20 from './img/FB_IMG_1689787673712.jpg';
import Img21 from './img/FB_IMG_1689787676879.jpg';
import Com1 from './img/trip/com1.jpeg';
import Com2 from './img/trip/com2.jpeg';
import Com3 from './img/trip/com3.jpeg';
import Com4 from './img/trip/com4.jpeg';
import Com5 from './img/trip/com5.jpeg';
import evan1 from './img/trip/evan1.jpeg';
import evan2 from './img/trip/evan2.jpeg';
import evan3 from './img/trip/evan3.jpeg';
import Carousel_ from 'react-grid-carousel'

function App() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [[evan3,'Evangelism In Tanzania (2024)'],[evan2,'Evangelism In Tanzania (2024)'],[evan1,'Evangelism In Tanzania (2024)'],[Com5,'Community Outreach In Tanzania (2024)'],[Com4,'Community Outreach In Tanzania (2024)'],[Com3,'Community Outreach In Tanzania (2024)'],[Com2,'Community Outreach In Tanzania (2024)'],[Com1,'Community Outreach In Tanzania (2024)'],[Img19,'Community Outreach In Tanzania'],[Img20,'Community Outreach In Tanzania'],[Img1,'Evangelism In Madagascar'],[Img2,'Evangelism In Madagascar'],[Img3,'Evangelism In Madagascar'],[Img4,'Evangelism In Madagascar'],[Img5,'Community Outreach In Madagascar'],[Img7,'Community Outreach In Madagascar'],[Img9,'Community Outreach In Ghana'],[Img10,'Community Outreach In Ghana'],[Img11,'Community Outreach In India'],[Img12,'Community Outreach In India'],[Img13,'Community Outreach In Kenya'],[Img14,'Community Outreach In Kenya'],[Img15,'Evangelism in Ethiopia'],[Img16,'Evangelism in Ethiopia'],[Img17,'Evangelism in Ethiopia'],[Img18,'Evangelism in Ethiopia']];
  const images_ = [evan3,evan2,evan1,Com5,Com4,Com3,Com2,Com1,Img19,Img20,Img1,Img2,Img3,Img4,Img5,Img7,Img9,Img10,Img11,Img12,Img13,Img14,Img15,Img16,Img17,Img18];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const Caroo=()=>{

    return(
<div>
<Carousel_ cols={4} rows={4} gap={10}  loop>

  {images.map((list,index)=>
    
        <Carousel_.Item onClick={ () => openImageViewer(index)}>
          <img width="100%" src={list[0]} style={{borderRadius:"8px", cursor:"pointer"}} onClick={ () => openImageViewer(index)}/>
          <br/>
          <p style={{margin:0, marginBottom:30}}><b>{list[1]}</b></p>
        </Carousel_.Item>
     

    )}
    </Carousel_>
        </div>
    )
  
  }
  

  return (
    <div>
      {/* {images.map((src, index) => (
       <> 
        <img
          src={ src }
          onClick={ () => openImageViewer(index) }
          width="300"
          key={index}
          caption="name"
          style={{ margin: '2px', cursor:"pointer", borderRadius:"6px" }}
          alt=""/> 
          </>
      ))} */}

<Caroo/>
      {isViewerOpen && (
        <ImageViewer
          src={ images_ }
          caption="name"

          currentIndex={ currentImage }
          onClose={ closeImageViewer }
        />
      )}




    </div>
  );
}


export default App