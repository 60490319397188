import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios'
import Call from '@material-ui/icons/Call';
import Email from '@material-ui/icons/Email';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import LocationOn from '@material-ui/icons/LocationOn';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Para1 from './img/contact1.jpg';
import Para2 from './img/contact2.jpg';
import Leave from './leave'
import AOS from 'aos';
import "aos/dist/aos.css";
import Badge from '@material-ui/core/Badge';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import PersonAddAlt1Icon from '@material-ui/icons/PersonAdd';
import swal from 'sweetalert';

import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Container, Divider } from '@material-ui/core';
// import Person from './images/Ellipse77.png';



const parallaxside={
    backgroundColor:"#63d471",
    backgroundImage: "linear-gradient(315deg, #2FA71B 0%, #0F6300 74%)",
    padding:30,
    color:"white",
    textAlign:"left"
    
    
 
    
}


const useStyles=makeStyles((theme)=>({
 
     root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '70%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        backgroundPosition:'center',  
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:400,
          paddingTop: '15%'
         
    }
    }
}));

const useStyles_ = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '48%',
      },
     
    },
  }));



function Example(props){
      AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    
    var items = [
        {
            name: <h1 style={{color:"white", fontSize:"48px", marginTop:0,}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12,backgroundColor:"rgba(0,0,0,0.5)"}}> Contact Us </span></h1>,
            image: Para2,
            description: ""
        },
        // {
        //     name: "WE ARE AVAILABLE",
        //      image: Para2,
        //     description: "We are here to serve you"
        // }
    ]

    const classes = useStyles_();

    const [person, setperson] = React.useState("Person")
    const [sex, setsex] = React.useState("")
    const [country, setcountry] = React.useState("")
    const [countryemergency, setcountryemergency] = React.useState("")
    const [health7, sethealth7] = React.useState("")
    const [loading, setloading]= React.useState(false)
    const [chosen, setchosen]= React.useState([])
    const [data_, setdata_]= React.useState([])




    
    const getalldata= async () => {
        await axios.post("https://server.3ammissionministry.com/api/forms/getdata/",
            {
                status:true


            }).then(res => {

                if (res.status === 200) {

                    setdata_(res.data)

                 


                }

            })

    }


    React.useEffect(()=>{

    getalldata()
    

 },[])




    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }

    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;
    const [agree, setagree]= React.useState(false)
    const [agree2, setagree2]= React.useState(false)



    const submitform=(e)=>{

        setloading(!true)

        e.preventDefault()
    
        const file = document.getElementById("fileimage").files[0]
        const image = ""

        const fd = new FormData()
                   
        if (file) {
       
            fd.append("file", file)
            fd.append("image", document.getElementById("fileimage").files[0].name)
        }
     
        fd.append("destination", document.getElementById("destination").value)
        fd.append("tripdate", document.getElementById("date").value)
        fd.append("firstname", document.getElementById("firstname").value)
        fd.append("middlename" , document.getElementById("middlename").value)
        fd.append("lastname" , document.getElementById("lastname").value)
        fd.append("dateofbirth" , document.getElementById("dateofbirth").value)
        fd.append("sex" , sex)
        fd.append("address" , document.getElementById("address").value)
        fd.append("city" , document.getElementById("city").value)
        fd.append("state" , document.getElementById("state").value)
        fd.append("country" ,country)
        fd.append("contact1" , document.getElementById("contact1").value)
        fd.append("contact2" , document.getElementById("contact2").value)
        fd.append("email" , document.getElementById("email").value)
        fd.append("passportnumber" , document.getElementById("passportnumber").value)
        fd.append("issueddate" , document.getElementById("issueddate").value)
        fd.append("expirationdate" , document.getElementById("expirationdate").value)
        fd.append("emergencyname" , document.getElementById("emergencyname").value)
        fd.append("addressemergency" , document.getElementById("addressemergency").value)
        fd.append("cityemergency" , document.getElementById("cityemergency").value)
        fd.append("stateemergency" , document.getElementById("stateemergency").value)
        fd.append("countryemergency" ,countryemergency)
        fd.append("contact1emergency" , document.getElementById("contact1emergency").value)
        fd.append("contact2emergency" , document.getElementById("contact2emergency").value)
        fd.append("emailemergency" , document.getElementById("emailemergency").value)
        fd.append("talent" , document.getElementById("talent").value)
        fd.append("employment" , document.getElementById("employment").value)
        fd.append("position" , document.getElementById("position").value)
        fd.append("duration" , document.getElementById("duration").value)
        fd.append("language" , document.getElementById("language").value)
        fd.append("skill" , document.getElementById("skill").value)
        fd.append("premission" , document.getElementById("premission").value)
        fd.append("strongquality" , document.getElementById("strongquality").value)
        fd.append("weakquality" , document.getElementById("weakquality").value)
        fd.append("health1" , document.getElementById("health1").value)
        fd.append("health2" , document.getElementById("health2").value)
        fd.append("health3" , document.getElementById("health3").value)
        fd.append("health4" , document.getElementById("health4").value)
        fd.append("health5" , document.getElementById("health5").value)
        fd.append("health6" , document.getElementById("health6").value)
        fd.append("health7" , health7)
    
        
const uploading = async() => {
          await axios.post("http://localhost:3001/api/message/sender/", fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }

    
                          }).then(res => {

                            if(res.status===200){
                                setloading(!true)

                                swal("Submission Successful", "Check your email to confirm submission", "success");
        
        
                            }else{
                                setloading(!true)
                                swal("Submission Failed", "", "error");
                            }
                          
    
       
    
          })
    
      }
    
    
      }
      

      const handleChangesex=(e)=>{
            setsex(e.target.value)
      }

      const handleChangecountry=(e)=>{
            setcountry(e.target.value)
      }  
      const handleChangecountryemergency=(e)=>{
            setcountryemergency(e.target.value)
      } 
      const handleChangehealth7=(e)=>{
            sethealth7(e.target.value)
      }


    return (
        <div>
        {/* <Carousel swipe={true} indicators={false} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel> */}






        
<div >
        <div style={{padding:"30px 5px 30px 5px", marginTop:"50px", zIndex:0}} >



<Grid container spacing={0} >

<Grid item md={4} sm={12} lg={4}>

<div style={{border:"solid", borderColor:"lightgray", borderRadius:"8px", borderWidth:"1px", margin:"10px", marginTop:"4%"}}>
<p style={{textAlign:"center"}}><b>REGISTRATION LIST</b></p>

<div style={{textAlign:"left"}}>

<ol>
{data_.map((list,index)=>
        <li> <Button onClick={(list)=>setchosen(list)}> {chosen.length!==0 && chosen.lastname +" "+ chosen.length!==0 && chosen.othername+" "+ chosen.length!==0 && chosen.firstname}</Button></li>
)
}

</ol>

</div>

</div>

</Grid>
<Grid item md={8} sm={12} lg={8}>
<div style={{border:"solid", borderColor:"lightgray", borderRadius:"8px", borderWidth:"1px", margin:"10px", marginTop:"2%", padding:"20px"}}>
<p ><b>PERSONAL INFORMATION</b></p>


<div style={{ width: "100%", textAlign: "center", marginBottom:"30px" }}>


<img style={{ width: "200px", border: "solid", borderColor: "#f0f2f0" }} alt="Recent_photo" src={"https://server.3ammissionministry.com/doc/routes/uploads"+chosen.length!==0 && chosen.image} />


</div>



<form onSubmit={submitform} className={classes.root} noValidate autoComplete="off">
  <Grid container style={{textAlign:"left"}} >
    
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Destination : </b>{chosen.length!==0 && chosen.destination}</p>
    </Grid>
       
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Date : </b>{chosen.length!==0 && chosen.tripdate}</p>
    </Grid>
    
 
    <Grid item lg={6} md={6} sm={12}>
        <p><b>First Name : </b>{chosen.length!==0 && chosen.firstname}</p>
    </Grid>
    

  <Grid item lg={6} md={6} sm={12}>
        <p><b>Middle Name : </b>{chosen.length!==0 && chosen.middlename}</p>
    </Grid>
    


  <Grid item lg={6} md={6} sm={12}>
        <p><b>Last Name :</b>{chosen.length!==0 && chosen.lastname}</p>
    </Grid>
    
  <Grid item lg={6} md={6} sm={12}>
        <p><b>Date Of Birth :</b>{chosen.length!==0 && chosen.dateofbirth}</p>
    </Grid>
        
  <Grid item lg={6} md={6} sm={12}>
        <p><b>Sex :</b>{chosen.length!==0 && chosen.sex}</p>
    </Grid>
    
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Address :</b>{chosen.length!==0 && chosen.address}</p>
    </Grid>
       
    <Grid item lg={6} md={6} sm={12}>
        <p><b>City :</b>{chosen.length!==0 && chosen.city}</p>
    </Grid>
    
    
    <Grid item lg={6} md={6} sm={12}>
        <p><b>State / Province / Region :</b>{chosen.length!==0 && chosen.state}</p>
    </Grid>
     
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Country :</b>{chosen.length!==0 && chosen.country}</p>
    </Grid>
      
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Day Tel :</b>{chosen.length!==0 && chosen.contact1}</p>
    </Grid>
        
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Cel Tel :</b>{chosen.length!==0 && chosen.contact2}</p>
    </Grid>
    
    
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Email :</b>{chosen.length!==0 && chosen.email}</p>
    </Grid>
    
 
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Passport Number :</b>{chosen.length!==0 && chosen.passportnumber}</p>
    </Grid>
    
 
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Issued Date :</b>{chosen.length!==0 && chosen.issueddate}</p>
    </Grid>
    
 
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Expiration Date :</b>{chosen.length!==0 && chosen.expirationdate}</p>
    </Grid>
    

    <Grid item lg={12} md={12} sm={12} style={{textAlign:"center"}}>
    <p style={{color:"grey"}}><i><b>In case of emergency, please notify:</b></i></p>
    </Grid>
    


    <Grid item lg={6} md={6} sm={12}>
        <p><b>Name :</b>{chosen.length!==0 && chosen.emergencyname}</p>
    </Grid>
    


    <Grid item lg={6} md={6} sm={12}>
        <p><b>Relationship :</b>{chosen.length!==0 && chosen.relationship}</p>
    </Grid>
    
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Address :</b>{chosen.length!==0 && chosen.addressemergency}</p>
    </Grid>
     <Grid item lg={6} md={6} sm={12}>
        <p><b>City :</b>{chosen.length!==0 && chosen.cityemergency}</p>
    </Grid>
    
     <Grid item lg={6} md={6} sm={12}>
        <p><b>State / Province / Region :</b>{chosen.length!==0 && chosen.stateemergency}</p>
    </Grid>
    <Grid item lg={6} md={6} sm={12}>
        <p><b>Country :</b>{chosen.length!==0 && chosen.countryemergency}</p>
    </Grid>
     <Grid item lg={6} md={6} sm={12}>
        <p><b>Home Tel :</b>{chosen.length!==0 && chosen.contact1emergency}</p>
    </Grid>
    
     <Grid item lg={6} md={6} sm={12}>
        <p><b>Work Tel :</b>{chosen.length!==0 && chosen.contact2emergency}</p>
    </Grid>
    
     <Grid item lg={6} md={6} sm={12}>
        <p><b>Email :</b>{chosen.length!==0 && chosen.emailemergency}</p>
    </Grid>
    


    </Grid>   
    
 
<br/>
<Divider></Divider>
   <p style={{color:"grey"}}><b>WORK EXPERIENCE / TALENTS</b></p>

<div style={{textAlign:"left"}}>

<ol>

<li style={{marginBottom:"20px"}}>Please list any specific talents that you have. (drama, singing, instruments, construction, medical, teaching, etc.) <br/>

<b>{chosen.length!==0 && chosen.talent}</b>

</li>

<li style={{marginBottom:"20px"}}>What are you employed? <br/>

<b>{chosen.length!==0 && chosen.emplyment}</b>
     <br/>
Position?  <b>{chosen.length!==0 && chosen.position}</b><br/>


     <br/>
     How Long?  <b>{chosen.length!==0 && chosen.duration}</b><br/>


</li>

<li style={{marginBottom:"20px"}}> Do you speak any foreign languages fluently? <br/>

<b>{chosen.length!==0 && chosen.language}</b>


</li>
<li style={{marginBottom:"20px"}}> Please indicate any special skills, talents, or Christian service experience that you feel may be helpful on this 
mission trip: <br/>

<b>{chosen.length!==0 && chosen.skill}</b>


</li>
<li style={{marginBottom:"20px"}}> Please list any previous mission/Outreach experience: <br/>

<b>{chosen.length!==0 && chosen.premission}</b>


</li>
<li style={{marginBottom:"20px"}}>What do you see as your strongest character quality and why? <br/>

<b>{chosen.length!==0 && chosen.stronquality}</b>


</li>
<li style={{marginBottom:"20px"}}>What do you see as your weakest character quality and why?<br/>
<b>{chosen.length!==0 && chosen.weakquality}</b>

</li>


</ol>

</div>

<Divider></Divider>
   <p style={{color:"grey"}}><b>HEALTH INFORMATION:</b></p>
<div  style={{textAlign:"left"}}>
<ol>

<li style={{marginBottom:"20px"}}>Do you have or have you ever had: 
Fainting Spells Heat Problems Diabetes Eating Disorder Respiratory Problems Seizures 
Other (please explain)?<br/>

<b>{chosen.length!==0 && chosen.health1}</b>

</li>


<li style={{marginBottom:"20px"}}>Do you have any condition which might affect your ability to fully function as a missionary on this trip (i.e., fear of 
flying, depression, anxiety, sleeping disorders)?<br/>

<b>{chosen.length!==0 && chosen.health}</b>



</li>

<li style={{marginBottom:"20px"}}>Do you have any chronic illnesses or allergies? Yes / No. if Yes, explain:<br/>

<b>{chosen.length!==0 && chosen.health2}</b>



</li>
<li style={{marginBottom:"20px"}}>. Are you presently under medication prescribed by a doctor? Yes / No. if yes explain:<br/>

<b>{chosen.length!==0 && chosen.health3}</b>



</li>
<li style={{marginBottom:"20px"}}>Have you ever had any psychiatric care or treatment? Yes / No if yes explain:<br/>
<b>{chosen.length!==0 && chosen.health4}</b>



</li>
<li style={{marginBottom:"20px"}}> Please list any hospitalization history:<br/>

<b>{chosen.length!==0 && chosen.health5}</b>



</li>

<li style={{marginBottom:"20px"}}>How would you describe your health and fitness?<br/>
<b>{chosen.length!==0 && chosen.health6}</b>



</li>


</ol>
   

<FormControl style={{width:"100%", marginTop:"10px"}}   >

<FormControlLabel
control={
<Checkbox
required
checked={chosen.length!==0 && agree}
onChange={()=>setagree(!agree)}
name="checkedB"
color="primary"
/>
}
label={<small style={{}}>I, THE UNDERSIGNED, DECLARE THAT ALL THE ABOUT-MENTIONED STATEMENTS ARE TRUE TO THE BEST OF MY 
KNOWLEDGE.</small>}
/>


</FormControl>


<Divider></Divider>
   <p style={{color:"grey", textAlign:"center"}}><b><i>Liability Release Statement</i></b></p>

   <FormControl style={{width:"100%", marginTop:"10px"}}   >

<FormControlLabel
control={
<Checkbox
required
checked={chosen.length!==0 && agree2}
// onChange={()=>setagree2(!agree2)}
name="checkedB"
color="primary"
/>
}
label={<small style={{}}>
    
    In consideration for participation in the 3AM Mission Ministry's mission trip,  I do hereby release, forever discharge and agree 
to hold harmless the 3AM Mission Ministry and its partners thereof from any and all liability, claims or 
demands for personal injury, sickness or death, as well as property damage and expenses, of any nature 
whatsoever which may be incurred by the undersigned, participating in the above descried trip or activity.
Furthermore, I hereby assume all risk of personal injury, sickness, death, damage, and expenses as a result 
of participation in recreation and work activities, food, and lodging for this participant. The undersigned 
further hereby agree to hold harmless and indemnify said organization, its directors and partners, for any 
liability sustained by said organization as the result of the negligent, willful or intentional acts of said 
participant, including expenses incurred attendant thereto.
Further, should it be necessary for the participant to return home due to disciplinary actions, medical 
reasons or otherwise, I hereby assume all expenses

    </small>}
/>
</FormControl>

   </div>
     <div align="center">
  
     </div>
    
   </form>




</div>

</Grid>

</Grid>


            </div>
            </div>
     
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.8484336332685!2d-76.81632558464938!3d38.90458117956952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7eab2795cc2e9%3A0x3b7e3088d8779414!2s1011%20Winged%20Foot%20Dr%2C%20Bowie%2C%20MD%2020721%2C%20USA!5e0!3m2!1sen!2sgh!4v1606680803222!5m2!1sen!2sgh" width="100%" height="400" style={{border:0, allowfullscreen:""}} title="location" loading="lazy"></iframe>


    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
    return (
        
      
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
            <h1 style={{color:"white", fontSize:"33px", marginTop:30}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12}}>{props.item.name} </span></h1>
            <p style={{color:"white", fontSize:"16px"}} data-aos={"zoom-in"}>{props.item.description}</p>

          
        
        
        </div>
        
     

    )
}


export default Example

const Countries = [ 
    {name: 'Afghanistan', code: 'AF'}, 
    {name: 'Åland Islands', code: 'AX'}, 
    {name: 'Albania', code: 'AL'}, 
    {name: 'Algeria', code: 'DZ'}, 
    {name: 'American Samoa', code: 'AS'}, 
    {name: 'AndorrA', code: 'AD'}, 
    {name: 'Angola', code: 'AO'}, 
    {name: 'Anguilla', code: 'AI'}, 
    {name: 'Antarctica', code: 'AQ'}, 
    {name: 'Antigua and Barbuda', code: 'AG'}, 
    {name: 'Argentina', code: 'AR'}, 
    {name: 'Armenia', code: 'AM'}, 
    {name: 'Aruba', code: 'AW'}, 
    {name: 'Australia', code: 'AU'}, 
    {name: 'Austria', code: 'AT'}, 
    {name: 'Azerbaijan', code: 'AZ'}, 
    {name: 'Bahamas', code: 'BS'}, 
    {name: 'Bahrain', code: 'BH'}, 
    {name: 'Bangladesh', code: 'BD'}, 
    {name: 'Barbados', code: 'BB'}, 
    {name: 'Belarus', code: 'BY'}, 
    {name: 'Belgium', code: 'BE'}, 
    {name: 'Belize', code: 'BZ'}, 
    {name: 'Benin', code: 'BJ'}, 
    {name: 'Bermuda', code: 'BM'}, 
    {name: 'Bhutan', code: 'BT'}, 
    {name: 'Bolivia', code: 'BO'}, 
    {name: 'Bosnia and Herzegovina', code: 'BA'}, 
    {name: 'Botswana', code: 'BW'}, 
    {name: 'Bouvet Island', code: 'BV'}, 
    {name: 'Brazil', code: 'BR'}, 
    {name: 'British Indian Ocean Territory', code: 'IO'}, 
    {name: 'Brunei Darussalam', code: 'BN'}, 
    {name: 'Bulgaria', code: 'BG'}, 
    {name: 'Burkina Faso', code: 'BF'}, 
    {name: 'Burundi', code: 'BI'}, 
    {name: 'Cambodia', code: 'KH'}, 
    {name: 'Cameroon', code: 'CM'}, 
    {name: 'Canada', code: 'CA'}, 
    {name: 'Cape Verde', code: 'CV'}, 
    {name: 'Cayman Islands', code: 'KY'}, 
    {name: 'Central African Republic', code: 'CF'}, 
    {name: 'Chad', code: 'TD'}, 
    {name: 'Chile', code: 'CL'}, 
    {name: 'China', code: 'CN'}, 
    {name: 'Christmas Island', code: 'CX'}, 
    {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
    {name: 'Colombia', code: 'CO'}, 
    {name: 'Comoros', code: 'KM'}, 
    {name: 'Congo', code: 'CG'}, 
    {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
    {name: 'Cook Islands', code: 'CK'}, 
    {name: 'Costa Rica', code: 'CR'}, 
    {name: 'Cote D\'Ivoire', code: 'CI'}, 
    {name: 'Croatia', code: 'HR'}, 
    {name: 'Cuba', code: 'CU'}, 
    {name: 'Cyprus', code: 'CY'}, 
    {name: 'Czech Republic', code: 'CZ'}, 
    {name: 'Denmark', code: 'DK'}, 
    {name: 'Djibouti', code: 'DJ'}, 
    {name: 'Dominica', code: 'DM'}, 
    {name: 'Dominican Republic', code: 'DO'}, 
    {name: 'Ecuador', code: 'EC'}, 
    {name: 'Egypt', code: 'EG'}, 
    {name: 'El Salvador', code: 'SV'}, 
    {name: 'Equatorial Guinea', code: 'GQ'}, 
    {name: 'Eritrea', code: 'ER'}, 
    {name: 'Estonia', code: 'EE'}, 
    {name: 'Ethiopia', code: 'ET'}, 
    {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
    {name: 'Faroe Islands', code: 'FO'}, 
    {name: 'Fiji', code: 'FJ'}, 
    {name: 'Finland', code: 'FI'}, 
    {name: 'France', code: 'FR'}, 
    {name: 'French Guiana', code: 'GF'}, 
    {name: 'French Polynesia', code: 'PF'}, 
    {name: 'French Southern Territories', code: 'TF'}, 
    {name: 'Gabon', code: 'GA'}, 
    {name: 'Gambia', code: 'GM'}, 
    {name: 'Georgia', code: 'GE'}, 
    {name: 'Germany', code: 'DE'}, 
    {name: 'Ghana', code: 'GH'}, 
    {name: 'Gibraltar', code: 'GI'}, 
    {name: 'Greece', code: 'GR'}, 
    {name: 'Greenland', code: 'GL'}, 
    {name: 'Grenada', code: 'GD'}, 
    {name: 'Guadeloupe', code: 'GP'}, 
    {name: 'Guam', code: 'GU'}, 
    {name: 'Guatemala', code: 'GT'}, 
    {name: 'Guernsey', code: 'GG'}, 
    {name: 'Guinea', code: 'GN'}, 
    {name: 'Guinea-Bissau', code: 'GW'}, 
    {name: 'Guyana', code: 'GY'}, 
    {name: 'Haiti', code: 'HT'}, 
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
    {name: 'Holy See (Vatican City State)', code: 'VA'}, 
    {name: 'Honduras', code: 'HN'}, 
    {name: 'Hong Kong', code: 'HK'}, 
    {name: 'Hungary', code: 'HU'}, 
    {name: 'Iceland', code: 'IS'}, 
    {name: 'India', code: 'IN'}, 
    {name: 'Indonesia', code: 'ID'}, 
    {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
    {name: 'Iraq', code: 'IQ'}, 
    {name: 'Ireland', code: 'IE'}, 
    {name: 'Isle of Man', code: 'IM'}, 
    {name: 'Israel', code: 'IL'}, 
    {name: 'Italy', code: 'IT'}, 
    {name: 'Jamaica', code: 'JM'}, 
    {name: 'Japan', code: 'JP'}, 
    {name: 'Jersey', code: 'JE'}, 
    {name: 'Jordan', code: 'JO'}, 
    {name: 'Kazakhstan', code: 'KZ'}, 
    {name: 'Kenya', code: 'KE'}, 
    {name: 'Kiribati', code: 'KI'}, 
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
    {name: 'Korea, Republic of', code: 'KR'}, 
    {name: 'Kuwait', code: 'KW'}, 
    {name: 'Kyrgyzstan', code: 'KG'}, 
    {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
    {name: 'Latvia', code: 'LV'}, 
    {name: 'Lebanon', code: 'LB'}, 
    {name: 'Lesotho', code: 'LS'}, 
    {name: 'Liberia', code: 'LR'}, 
    {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
    {name: 'Liechtenstein', code: 'LI'}, 
    {name: 'Lithuania', code: 'LT'}, 
    {name: 'Luxembourg', code: 'LU'}, 
    {name: 'Macao', code: 'MO'}, 
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
    {name: 'Madagascar', code: 'MG'}, 
    {name: 'Malawi', code: 'MW'}, 
    {name: 'Malaysia', code: 'MY'}, 
    {name: 'Maldives', code: 'MV'}, 
    {name: 'Mali', code: 'ML'}, 
    {name: 'Malta', code: 'MT'}, 
    {name: 'Marshall Islands', code: 'MH'}, 
    {name: 'Martinique', code: 'MQ'}, 
    {name: 'Mauritania', code: 'MR'}, 
    {name: 'Mauritius', code: 'MU'}, 
    {name: 'Mayotte', code: 'YT'}, 
    {name: 'Mexico', code: 'MX'}, 
    {name: 'Micronesia, Federated States of', code: 'FM'}, 
    {name: 'Moldova, Republic of', code: 'MD'}, 
    {name: 'Monaco', code: 'MC'}, 
    {name: 'Mongolia', code: 'MN'}, 
    {name: 'Montserrat', code: 'MS'}, 
    {name: 'Morocco', code: 'MA'}, 
    {name: 'Mozambique', code: 'MZ'}, 
    {name: 'Myanmar', code: 'MM'}, 
    {name: 'Namibia', code: 'NA'}, 
    {name: 'Nauru', code: 'NR'}, 
    {name: 'Nepal', code: 'NP'}, 
    {name: 'Netherlands', code: 'NL'}, 
    {name: 'Netherlands Antilles', code: 'AN'}, 
    {name: 'New Caledonia', code: 'NC'}, 
    {name: 'New Zealand', code: 'NZ'}, 
    {name: 'Nicaragua', code: 'NI'}, 
    {name: 'Niger', code: 'NE'}, 
    {name: 'Nigeria', code: 'NG'}, 
    {name: 'Niue', code: 'NU'}, 
    {name: 'Norfolk Island', code: 'NF'}, 
    {name: 'Northern Mariana Islands', code: 'MP'}, 
    {name: 'Norway', code: 'NO'}, 
    {name: 'Oman', code: 'OM'}, 
    {name: 'Pakistan', code: 'PK'}, 
    {name: 'Palau', code: 'PW'}, 
    {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
    {name: 'Panama', code: 'PA'}, 
    {name: 'Papua New Guinea', code: 'PG'}, 
    {name: 'Paraguay', code: 'PY'}, 
    {name: 'Peru', code: 'PE'}, 
    {name: 'Philippines', code: 'PH'}, 
    {name: 'Pitcairn', code: 'PN'}, 
    {name: 'Poland', code: 'PL'}, 
    {name: 'Portugal', code: 'PT'}, 
    {name: 'Puerto Rico', code: 'PR'}, 
    {name: 'Qatar', code: 'QA'}, 
    {name: 'Reunion', code: 'RE'}, 
    {name: 'Romania', code: 'RO'}, 
    {name: 'Russian Federation', code: 'RU'}, 
    {name: 'RWANDA', code: 'RW'}, 
    {name: 'Saint Helena', code: 'SH'}, 
    {name: 'Saint Kitts and Nevis', code: 'KN'}, 
    {name: 'Saint Lucia', code: 'LC'}, 
    {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
    {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
    {name: 'Samoa', code: 'WS'}, 
    {name: 'San Marino', code: 'SM'}, 
    {name: 'Sao Tome and Principe', code: 'ST'}, 
    {name: 'Saudi Arabia', code: 'SA'}, 
    {name: 'Senegal', code: 'SN'}, 
    {name: 'Serbia and Montenegro', code: 'CS'}, 
    {name: 'Seychelles', code: 'SC'}, 
    {name: 'Sierra Leone', code: 'SL'}, 
    {name: 'Singapore', code: 'SG'}, 
    {name: 'Slovakia', code: 'SK'}, 
    {name: 'Slovenia', code: 'SI'}, 
    {name: 'Solomon Islands', code: 'SB'}, 
    {name: 'Somalia', code: 'SO'}, 
    {name: 'South Africa', code: 'ZA'}, 
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
    {name: 'Spain', code: 'ES'}, 
    {name: 'Sri Lanka', code: 'LK'}, 
    {name: 'Sudan', code: 'SD'}, 
    {name: 'Suriname', code: 'SR'}, 
    {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
    {name: 'Swaziland', code: 'SZ'}, 
    {name: 'Sweden', code: 'SE'}, 
    {name: 'Switzerland', code: 'CH'}, 
    {name: 'Syrian Arab Republic', code: 'SY'}, 
    {name: 'Taiwan, Province of China', code: 'TW'}, 
    {name: 'Tajikistan', code: 'TJ'}, 
    {name: 'Tanzania, United Republic of', code: 'TZ'}, 
    {name: 'Thailand', code: 'TH'}, 
    {name: 'Timor-Leste', code: 'TL'}, 
    {name: 'Togo', code: 'TG'}, 
    {name: 'Tokelau', code: 'TK'}, 
    {name: 'Tonga', code: 'TO'}, 
    {name: 'Trinidad and Tobago', code: 'TT'}, 
    {name: 'Tunisia', code: 'TN'}, 
    {name: 'Turkey', code: 'TR'}, 
    {name: 'Turkmenistan', code: 'TM'}, 
    {name: 'Turks and Caicos Islands', code: 'TC'}, 
    {name: 'Tuvalu', code: 'TV'}, 
    {name: 'Uganda', code: 'UG'}, 
    {name: 'Ukraine', code: 'UA'}, 
    {name: 'United Arab Emirates', code: 'AE'}, 
    {name: 'United Kingdom', code: 'GB'}, 
    {name: 'United States', code: 'US'}, 
    {name: 'United States Minor Outlying Islands', code: 'UM'}, 
    {name: 'Uruguay', code: 'UY'}, 
    {name: 'Uzbekistan', code: 'UZ'}, 
    {name: 'Vanuatu', code: 'VU'}, 
    {name: 'Venezuela', code: 'VE'}, 
    {name: 'Viet Nam', code: 'VN'}, 
    {name: 'Virgin Islands, British', code: 'VG'}, 
    {name: 'Virgin Islands, U.S.', code: 'VI'}, 
    {name: 'Wallis and Futuna', code: 'WF'}, 
    {name: 'Western Sahara', code: 'EH'}, 
    {name: 'Yemen', code: 'YE'}, 
    {name: 'Zambia', code: 'ZM'}, 
    {name: 'Zimbabwe', code: 'ZW'} 
  ]
