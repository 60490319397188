import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Sustain from './img/sustainable.jpg';
import Responsible from './img/responsible.jpg';
import Commitment from './img/commitment.jpg';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Para2 from './img/img/loader1.jpg';
import Para1 from './img/slider/team.jpg';
import Ronak from './img/ronak.jpg';
import Mitul from './img/mitul.jpg';
import Appiah from './img/appiah.jpeg';
import Amit from './img/amit.jpeg';
import Wm from './img/people/wm.jpg';
import Smith from './img/people/smith.jpg';
import Wash from './img/people/wash.jpg';
import Samanth from './img/people/samanth.jpg';
import Doc from './img/people/doctor.jpg';
import Buggan from './img/people/buggan.jpg';
import Ongaga from './img/people/ngoga.jpg';
import Joe from './img/people/joe.jpg';
import Noimage from './img/people/none.png';
import Longman from './img/people/longman.jpg';
import Pambour from './img/people/pambour.jpeg';
import Okello from './img/people/okello.png';
import Lutero from './img/people/Lutero.jpg';
import Fleurot from './img/people/fleurot.jpg';
import Sharlton from './img/people/sharlton.jpg';
import Vil from './img/people/vil.jpg';
import Avatar from '@material-ui/core/Avatar';
import AOS from 'aos';
import "aos/dist/aos.css";
import Typography from '@material-ui/core/Typography';

const imager=[
  {image:Wm ,name:"Elder Wayne M. Bristol", position:"Executive Chairman"},
  {image:Smith ,name:"Elder Winsome G. Smith", position:"Deputy Executive Chairman"},
  {image:Wash ,name:"Mr. George Washington", position:"Executive Secretary"},
  {image:Samanth ,name:"Ms. Samantha Williams", position:"Executive Treasurer"},
  {image:Doc ,name:"Dr. Luke M. Sylvester MD", position:"Medical Outreach Director"},
  {image:Buggan ,name:"Elder Dorrett Buggan", position:"Prayer Director"},
  {image:Ongaga ,name:"Mr. Timothy Ongaga Oroko", position:"Community Outreach Director"},
  {image:Joe ,name:"Mr. Ntori Joseph Ngissah ", position:"Communications Director "},
  {image:Longman ,name:"Elder Janice & Duke Longman", position:"Chairman - North America"},
  {image:Noimage ,name:"...", position:"Chairman - Latin America & Caribbean"},
  {image:Pambour ,name:"Pastor A. A. Pambour", position:"Chairman - West Central Africa "},
  {image:Okello ,name:"Pastor Washingtone Okello", position:"Chairman - East Central Africa"},
  {image:Lutero ,name:"Pastor Lutero Ngola Gange", position:"Chairman - Southern Africa"},
  {image:Fleurot ,name:"Pastor Fleurot R. Haridjaorizy", position:"Chairman - Indian Ocean"},
  {image:Sharlton ,name:"Mr. Sharlton Shadrac J.", position:"Chairman - Southern Asia"},
  {image:Vil ,name:"Pastor Viliame N. Ratumaiyale", position:"Chairman - South Pacific"},

]

const parallaxside={ 
    backgroundColor:"#63d471",
    backgroundImage: "linear-gradient(315deg, #2FA71B 0%, #0F6300 74%)",
    padding:20,
    color:"white",
    textAlign:"left",
        
}


const useStyles=makeStyles((theme)=>({
    
     large:{
        
       [theme.breakpoints.up('xs')]: {
        width: theme.spacing(26),
        height: theme.spacing(26),
           marginLeft:'18%',
           marginBottom:"10px"
        },
       [theme.breakpoints.up('sm')]: {
        width: theme.spacing(26),
        height: theme.spacing(26),
           marginLeft:'15%',
           marginBottom:"10px"
        }
   
  },
 
     root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '70%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        backgroundPosition:'center',  
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:400,
          paddingTop: '15%'
         
    }
    }
}));





function Example(props){
      AOS.init({
    // initialise with other settings
    duration : 700
  });
    const classes = useStyles();
    
   var items = [
        {
          name: <h1 style={{color:"white", fontSize:"55px", marginTop:0,}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12,backgroundColor:"rgba(0,0,0,0.5)"}}> About Us </span></h1>,
            image: Para1,
            description: ""
        },
        // {
        //     name: "WE PROVIDE SUSTAINABLE ENERGY",
        //      image: Para2,
        //     description: "Get affordable Biomass Energy"
        // }
    ]

    return (
        <div>
        <Carousel swipe={true} indicators={false} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

    <br/>
<Container  maxWidth="lg">
         <h2 className="MuiTypography-h4" data-aos={"fade-up"} style={{textAlign:"center"}}>
        3AM MISSION MINISTRY
            </h2>
            <div align="left">
<p style={{textAlign:"center", marginTop:"-25px", fontSize:"20px", color:"goldenrod"}} data-aos={"fade-up"}>Our core purpose is to spread the 3 Angels Message</p>

<p  data-aos="fade-up">
            In summer of 2010, <b>Elder Wayne Milton Bristol</b>, volunteered to go to India on a mission trip with the Seabrook Seventh Day Adventist Church, His home church in Lanham, Maryland, USA. He was selected to be the leader of this mission delegation, on this trip he conducted the week of prayer in high schools and presented the health message at the nightly evangelist meeting. <br/><br/>
            Since his first mission trip in 2010, Elder Bristol has been the International Mission Coordinator for His local SDA Church, and has not only had members from His church on mission trips, but from other churches in the Washington DC metropolitan area as well, and in 2018, He had a young Pastor from Ghana and a brother from Kenya joined his team on the mission trip to India as Seabrook celebrated one decade of missionary work in India. <br/><br/>
            Elder Bristol has been blessed to lead many more mission trips to preach the Everlasting gospel and share the love of Jesus in Haiti, Guyana, Kenya, Ethiopia, Fiji and Papua New Guinea to mention but a few. With his great passion for missions, He has formed the <b>WMB MISSION MINISTRIES</b> in spring 2018, with the main objective to mobilize, motivate and educate people to prepare for the second coming of Jesus. <br/><br/>
            In the winter of 2018, officers were elected to serve and lead in the following regions, West - Central Africa, East - Central Africa, Southern Africa - Indian Ocean, Southern Asia and South Pacific. In the spring of 2019, the name was change to <b>3AM MISSION MINISTRY</b> and in the spring of 2020 the regions of North America, Latin America and the Caribbean were added to our area of operations. Would you pray for us, and partner with us, as we go forward into all the world to share the good news that Jesus saves.
        </p>
            
        </div>
         
</Container>

<br/>
<br/>


   
       
<p>
            <Container  maxWidth="lg" style={{textAlign:"left"}}>
    
   
    
   <Grid container spacing={4} style={{marginTop:5,marginBottom:80}}>
      


  <Grid item xs={12} sm={4} md={6} lg={6} style={{textAlign:'left', marginTop:20}} data-aos={"fade-up"}>

            
            
           <Card className={classes.root}>
      <CardActionArea>
       
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          Our Vision
          </Typography>
          <Typography variant="body2" color="text" component="p">
          Our vision is to motivate and serve our communities and demonstrate the <br/> love of Jesus.
        
          </Typography>
        </CardContent>
      </CardActionArea>
      
    </Card> 
            
            
          
         </Grid> 


        <Grid item xs={12} sm={4} md={6} lg={6} style={{textAlign:'left', marginTop:20}} data-aos={"fade-up"}>

            
            <Card className={classes.root}>
      <CardActionArea>
        
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          Our Mission
          </Typography>
          <Typography variant="body2" color="text" component="p">
          Our Mission is to mobilize, motivate and educate people to prepare for the second coming of Jesus.          </Typography>
        </CardContent>
      </CardActionArea>
     
    </Card> 
            
            
            
            
            
         </Grid>
        </Grid> 
    
    
    
    
</Container> 

            </p>
            

 
   <div>
            
           
    <div  className="sms_m">    
    <Container  maxWidth="lg" style={{textAlign:"left"}} > 
            <br/>
            <br/>
        <h2 className="MuiTypography-h4" style={{textAlign:"center"}} data-aos={"fade-up"}>
        Meet Our Team
            </h2>
  
  <p align="center" data-aos={"fade-up"}>God has Blessed the 3AM Mission Ministry with a great team to execute this great commission He has given us,<br/> we are honoured to be a part of this great Commission.</p>
  <br/> 
  <br/>
<Grid container spacing={1} >
       

{
  imager.map((list,index)=>
 <Grid item xs={12} sm={3} md={3} style={{textAlign:'center', marginBottom:15}} data-aos={"fade-up"}> 
                
            <Avatar alt="Remy Sharp" src={list.image} className={classes.large} />
<Typography gutterBottom variant="h6" component="h2" data-aos={"fade-up"}>
          {list.name}
          </Typography>
<p style={{marginTop:"-12px", color:"grey"}} data-aos={"fade-up"}>
                   {list.position}
</p>

        
         </Grid> 
  )
}




    </Grid>

<br/>
<br/>
<br/>
    </Container> 
  </div>
     
    </div>
     
        


   
            

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.8484336332685!2d-76.81632558464938!3d38.90458117956952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7eab2795cc2e9%3A0x3b7e3088d8779114!2s1011%20Winged%20Foot%20Dr%2C%20Bowie%2C%20MD%2020721%2C%20USA!5e0!3m2!1sen!2sgh!4v1606680803222!5m2!1sen!2sgh" width="100%" height="400" style={{border:0, allowfullscreen:""}} title="location" loading="lazy"></iframe>
    
    
    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
    return (
        
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
            <h1 style={{color:"white", fontSize:"33px", marginTop:30}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12}}>{props.item.name} </span></h1>
            <p style={{color:"white", fontSize:"16px"}} data-aos={"zoom-in"}>{props.item.description}</p>

          
        
        
        </div>
        
        
     
    

    )
}


export default Example


