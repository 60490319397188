import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Power from './img/electric.jpg';
import Power1 from './img/power.jpg';
import Grid from '@material-ui/core/Grid';
import { Parallax } from "react-parallax";
import Para1 from './img/slider/outreach1.jpg';
import Para2 from './img/slide2.jpeg';
import Para3 from './img/img/loader3.jpg';
import Giver from './img/trip/give1.jpg';
import Comm from './img/slider/community.svg';
import Health from './img/slider/medical.svg';
import AOS from 'aos';
import "aos/dist/aos.css";


const insideStyles = {
//  background: "white",
  padding: "0%",
  position: "absolute",
  top: "49%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  fontSize:"272%",
  color:"white",
    textAlign:"center",
};    

const parallaxside={
     backgroundColor:"#63d471",
 backgroundImage: "linear-gradient(315deg, #2FA71B 0%, #0F6300 74%)",
    padding:20,
    color:"white",
    textAlign:"left"
    
    
 
    
}


const useStyles=makeStyles((theme)=>({

     root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '70%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        backgroundPosition:'center',  
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:400,
          paddingTop: '15%'
         
    }
    }
}));





function Example(props)

{
     AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    
  var items = [
        {
          name: <h1 style={{color:"white", fontSize:"48px", marginTop:0,}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12,backgroundColor:"rgba(0,0,0,0.5)"}}>Outreach</span></h1>,
            image: Para1,
            description: ""
        },
    
        // {
        //     name: "Outreach",
        //      image: Para2,
        //     description: "The best renewable energy"
        // }
    ]

    return (
        <div>
        <Carousel swipe={true} indicators={false} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

   

    <Container  maxWidth="lg">
      
        
        
</Container>
<br/>
<br/>
<h1 style={{marginTop:1, textAlign:"center"}} data-aos="fade-up">
 
  Outreach</h1>

  <br/>
<br/>
        <Grid container spacing={0} >
        <Grid item xs={12} sm={6} md={6} style={{textAlign:'left'}}>

            
<div style={{}}>    
 <img src={Giver} width="100%" style={{heigth:"101%"}} data-aos="fade-in"/>

 </div>   
       
       
    </Grid>
        <Grid item xs={12} sm={6} md={6} style={{padding:30}}>

          <h1  style={{marginTop:10, textAlign:"center"}} data-aos="fade-up"> <img src={Comm} width="25%"/> <br/> Community Outreach</h1>
       

            <p style={{marginBottom:45, textAlign:"left"}} data-aos="fade-up">
            We base our Community Outreach program on the Gospel of Jesus, Matthew 25: 42, 43 & 45 For I was hungry and you gave me no food, I was thirsty and you gave me no drink. I was a stranger and you did not take me in, naked and you did not clothe me, sick and in prison and you did not visit me. Then he will answer them, saying, assuredly, I say to you, inasmuch as you did not do it to one of the least of these, you did not do it to me.
            
            </p>
         </Grid> 
       
        </Grid>
            
        <Grid container spacing={0} >
        <Grid item xs={12} sm={6} md={6} style={{padding:30}}>

<h1 style={{marginTop:1, textAlign:"center"}} data-aos="fade-up">
  <br/>
   <img src={Health} width="25%"/> <br/>
  Health and Medical Outreach</h1>
<div align="left">
  <p style={{marginBottom:45, textAlign:"left"}} data-aos="fade-up">
  For Health and Medical Outreach, we promote the 8 Bible Secrets for a longer and stronger life. The Amazing Health Benefits when we use The Newstart Lifestyle.  
  </p>
</div>
</Grid> 

        <Grid item xs={12} sm={6} md={6} style={{textAlign:'left', marginTop:"-7px"}} className="sms_m">

            
        <div align="left" style={{padding:30}}>
        <p data-aos="fade-up"><b>Nutrition: </b>Discover why what you eat is the foundation of better health, and healthy eating can be a lot more fun than you thought.</p>
  <p data-aos="fade-up"><b>Exercise: </b>Everyone knows they need exercise, but you won't believe how far a little exercise will go in keeping you fit.</p>

<p data-aos="fade-up"><b>Water: </b>Millions are chronically dehydrated, and the are aging quicker and dying younger because of it, get the facts about H20</p>
  <p data-aos="fade-up"><b>Sunlight: </b>For years the sun had endured a bad reputation, but it is a key ingredient for better health.  </p>
  <p data-aos="fade-up"><b>Temperance: </b>Add years to your life by learning to use the good things moderately and avoiding the bad things entirely.</p>
  <p data-aos="fade-up"><b>Air: </b>The air you breathe and how well you breathe it has a huge impact on how long you remain a living being.  </p>
  <p data-aos="fade-up"><b>Rest: </b>Considering that sleep is a crucial element in healing, most people are in for a disease-ridden wake up call.  </p>
  <p data-aos="fade-up"><b>Trust: </b>Nothing you've learned so far will make as much difference in your life as putting your faith in the One who made you.  </p>
  </div>
            
            
         </Grid>

 


        </Grid>
            


            
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.8484336332685!2d-76.81632558464938!3d38.90458117956952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7eab2795cc2e9%3A0x3b7e3088d8779114!2s1011%20Winged%20Foot%20Dr%2C%20Bowie%2C%20MD%2020721%2C%20USA!5e0!3m2!1sen!2sgh!4v1606680803222!5m2!1sen!2sgh" width="100%" height="400" style={{border:0, allowfullscreen:""}} title="location" loading="lazy"></iframe>

    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
     return (
         
         
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
            <h1 style={{color:"white", fontSize:"33px", marginTop:30}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12}}>{props.item.name} </span></h1>
            <p style={{color:"white", fontSize:"16px"}} data-aos={"zoom-in"}>{props.item.description}</p>

          
        
        
        </div>
        
        

    )
}


export default Example


