import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Call from '@material-ui/icons/Call';
import Email from '@material-ui/icons/Email';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import LocationOn from '@material-ui/icons/LocationOn';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Para1 from './img/contact1.jpg';
import Para2 from './img/contact2.jpg';
import Leave from './leave'
import AOS from 'aos';
import "aos/dist/aos.css";



const parallaxside={
    backgroundColor:"#63d471",
    backgroundImage: "linear-gradient(315deg, #2FA71B 0%, #0F6300 74%)",
    padding:30,
    color:"white",
    textAlign:"left"
    
    
 
    
}


const useStyles=makeStyles((theme)=>({
 
     root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '70%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        backgroundPosition:'center',  
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:400,
          paddingTop: '15%'
         
    }
    }
}));





function Example(props){
      AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    
    var items = [
        {
            name: <h1 style={{color:"white", fontSize:"48px", marginTop:0,}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12,backgroundColor:"rgba(0,0,0,0.5)"}}> Contact Us </span></h1>,
            image: Para2,
            description: ""
        },
        // {
        //     name: "WE ARE AVAILABLE",
        //      image: Para2,
        //     description: "We are here to serve you"
        // }
    ]


    return (
        <div>
        <Carousel swipe={true} indicators={false} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

 <div className='sms_m'>   
 <br/>
 <br/>
            <h1  style={{marginTop:30}} data-aos={"fade-up"}>Get In Touch</h1>
        <p className="MuiTypography-h6" style={{marginTop:'-29px'}}></p>
<br/>
<br/>

        <Grid container spacing={0} >
        <Grid item xs={12} sm={6} md={6}  >

        <h3 style={{marginTop:'30px',textAlign:'center'}} data-aos={"fade-up"}>Our Contacts</h3>

           <Grid item  xs={12} sm={12} md={12} >
        
        <Grid container spacing={1} style={{textAlign:'center'}}>
         
<Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}}data-aos={"fade-up"}>
        <Email/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}}data-aos={"fade-up"}>
        <a href='mailto:support@3ammissionministry.com'>support@3ammissionministry.com</a>
         </Grid>
    <Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        <Call/>
         </Grid>
        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
        <a href='tel:+144 38087618'>+144 38087618 </a>
         </Grid>

<Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}} data-aos={"fade-up"}>
        <LocationOn/>
         </Grid>

        <Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <a href="https://goo.gl/maps/kuEJyQHjEerrqNYR7"> 1011 Winged Foot Drive, Bowie, Maryland, 20721, USA</a>        
         </Grid>

         <Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}}data-aos={"fade-up"}>
        <Facebook/>
         </Grid>
<Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <a href="https://web.facebook.com/search/top?q=3am%20mission%20ministries"> Facebook</a>        
         </Grid>

         <Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}}data-aos={"fade-up"}>
        <Instagram/>
         </Grid>
<Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <a href="https://www.instagram.com/3ammission/"> Instagram</a>        
         </Grid>

         <Grid item xs={2} sm={2} md={2} style={{textAlign:'right', paddingBottom:'0px'}}data-aos={"fade-up"}>
        <Twitter/>
         </Grid>
<Grid item xs={10} sm={10} md={10} style={{textAlign:'left', paddingTop:'6px'}} data-aos={"fade-up"}>
          <a href="https://twitter.com/3amMission"> Twitter</a>        
         </Grid>

        </Grid>
    
        </Grid>
        
        
         </Grid> 

        <Grid item xs={12} sm={6} md={6} style={{textAlign:'left', padding:15}}data-aos={"fade-up"}>

        <h3  style={{marginTop:'10px',textAlign:'left'}} data-aos={"fade-up"}>Leave A Message</h3>
        <p className="MuiTypography-h6" style={{marginTop:'-29px'}}></p>
<br/>
            <p style={{marginBottom:80}}>
           <Leave/>
            </p>
            
            
            
         </Grid>
        </Grid>
      </div>      
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.8484336332685!2d-76.81632558464938!3d38.90458117956952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7eab2795cc2e9%3A0x3b7e3088d8779114!2s1011%20Winged%20Foot%20Dr%2C%20Bowie%2C%20MD%2020721%2C%20USA!5e0!3m2!1sen!2sgh!4v1606680803222!5m2!1sen!2sgh" width="100%" height="400" style={{border:0, allowfullscreen:""}} title="location" loading="lazy"></iframe>


    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
    return (
        
      
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
            <h1 style={{color:"white", fontSize:"33px", marginTop:30}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12}}>{props.item.name} </span></h1>
            <p style={{color:"white", fontSize:"16px"}} data-aos={"zoom-in"}>{props.item.description}</p>

          
        
        
        </div>
        
     

    )
}


export default Example


