import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Power from './img/electric.jpg';
import Power1 from './img/power.jpg';
import Grid from '@material-ui/core/Grid';
import { Parallax } from "react-parallax";
import Para1 from './img/slider/donate.jpg';
import Scanner from './img/slider/scanner.png';
import Para2 from './img/slide2.jpeg';
import Para3 from './img/img/loader3.jpg';
import AOS from 'aos';
import "aos/dist/aos.css";


const insideStyles = {
//  background: "white",
  padding: "0%",
  position: "absolute",
  top: "49%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  fontSize:"272%",
  color:"white",
    textAlign:"center",
};    

const parallaxside={
     backgroundColor:"#63d471",
 backgroundImage: "linear-gradient(315deg, #2FA71B 0%, #0F6300 74%)",
    padding:20,
    color:"white",
    textAlign:"left"
    
    
 
    
}


const useStyles=makeStyles((theme)=>({

     root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '70%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        backgroundPosition:'center',  
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:400,
          paddingTop: '15%'
         
    }
    }
}));





function Example(props)

{
     AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    
  var items = [
        {
          name: <h1 style={{color:"white", fontSize:"34px", marginTop:0,}} data-aos={"flip-right"}><span style={{ padding:12, backgroundColor:"rgba(0,0,0,0.5)"}}>Support The Mission</span></h1>,
            image: Para1,
            description: ""
        },
    
       
    ]

    return (
        <div>
        <Carousel swipe={true} touch={true} indicators={false} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

    <br/>
    <br/>


    <Container  maxWidth="md">
         <p data-aos="fade-up" style={{textAlign:"left"}}>
         
         The Lord promises to bless us as we faithfully give. He said, “Give, and it will be given to you. A good measure, pressed down, shaken together and running over, will be poured into your lap. For with the measure you use, it will be measured to you.” (Luke 6:38)
The blessings we have been promised are both material and spiritual.

            </p>
          

<Divider data-aos="fade-up"/> 
<br/>
<h3 align="center" data-aos="fade-up" > Send Your Donation </h3>

<Grid container>


<Grid xs={12}md={6} sm={12} lg={6}>
<br/>
<br/>
<div class="container text-center">
                            
                            
                            <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="hosted_button_id" value="GWSM4SFC5S3K2" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                            
                            
                            
                                                   
                                                    </div>

</Grid>

<Grid xs={12}md={6} sm={12} lg={6} align="center">

<p><b>Scan To Donate</b></p>

<img src={Scanner} width="40%" />


</Grid>



</Grid>
 
 
        
        
</Container>

    
  <br/>
  <br/>
  <br/>


 


            
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.8484336332685!2d-76.81632558464938!3d38.90458117956952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7eab2795cc2e9%3A0x3b7e3088d8779114!2s1011%20Winged%20Foot%20Dr%2C%20Bowie%2C%20MD%2020721%2C%20USA!5e0!3m2!1sen!2sgh!4v1606680803222!5m2!1sen!2sgh" width="100%" height="400" style={{border:0, allowfullscreen:""}} title="location" loading="lazy"></iframe>

    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
     return (
         
         
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
            <h1 style={{color:"white", fontSize:"33px", marginTop:30}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12}}>{props.item.name} </span></h1>
            <p style={{color:"white", fontSize:"16px"}} data-aos={"zoom-in"}>{props.item.description}</p>

          
        
        
        </div>
        
        

    )
}


export default Example


