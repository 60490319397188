import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Power from './img/electric.jpg';
import Power1 from './img/power.jpg';
import Grid from '@material-ui/core/Grid';
import { Parallax } from "react-parallax";
import Para1 from './img/slider/evan.jpg';
import Para2 from './img/slide2.jpeg';
import Para3 from './img/img/loader3.jpg';
import AOS from 'aos';
import "aos/dist/aos.css";


const insideStyles = {
//  background: "white",
  padding: "0%",
  position: "absolute",
  top: "49%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  fontSize:"272%",
  color:"white",
    textAlign:"center",
};    

const parallaxside={
     backgroundColor:"#63d471",
 backgroundImage: "linear-gradient(315deg, #2FA71B 0%, #0F6300 74%)",
    padding:20,
    color:"white",
    textAlign:"left"
    
    
 
    
}


const useStyles=makeStyles((theme)=>({

     root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '70%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        backgroundPosition:'center',  
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:400,
          paddingTop: '15%'
         
    }
    }
}));





function Example(props)

{
     AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    
  var items = [
        {
          name: <h1 style={{color:"white", fontSize:"48px", marginTop:20,}} data-aos={"flip-right"}><span style={{ padding:12, backgroundColor:"rgba(0,0,0,0.5)"}}>Evangelism </span> </h1>,
            image: Para1,
            description: ""
        },
    
       
    ]

    return (
        <div>
        <Carousel swipe={true} touch={true} indicators={false} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

    <br/>
    <br/>


    <Container  maxWidth="md">
         <p data-aos="fade-up" style={{textAlign:"left"}}>
         
The Great Gospel Commission given to us by Jesus in Matthew 28:18-20, tells us to go ye therefore, and make disciples of All Nations, baptizing them in the name of the Father and of the Son and the Holy Spirit.


            </p>


     <p data-aos="fade-up" style={{textAlign:"left"}}>

Because our mission is to mobilize, motivate and education people to prepare for Jesus' 2nd coming. We organize short term mission trips to various countries around the world and travel to some remote areas in these countries to spread the Gospel of Jesus, by having evangelist meetings, Vacation Bible School for children, week of prayer in schools. Free Bibles in local language and other spiritual literature are distributed, and we address some basic needs of the people.

            </p>


     <p data-aos="fade-up" style={{textAlign:"left"}}>

Please pray that God will give us wisdom and strength to keep going forward with our work, in these last days of earth's history to share the Good News that Jesus saves and he is coming again.
            </p>


 
        
        
</Container>

    
  <br/>
  <br/>
  <br/>


 


            
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.8484336332685!2d-76.81632558464938!3d38.90458117956952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7eab2795cc2e9%3A0x3b7e3088d8779114!2s1011%20Winged%20Foot%20Dr%2C%20Bowie%2C%20MD%2020721%2C%20USA!5e0!3m2!1sen!2sgh!4v1606680803222!5m2!1sen!2sgh" width="100%" height="400" style={{border:0, allowfullscreen:""}} title="location" loading="lazy"></iframe>

    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
     return (
         
         
        <div className={classes.caro} style={{backgroundImage: `url(${props.item.image})`}}>
            <h1 style={{color:"white", fontSize:"33px", marginTop:30}} data-aos={"flip-right"}><span style={{ paddingLeft:12, paddingRight:12}}>{props.item.name} </span></h1>
            <p style={{color:"white", fontSize:"16px"}} data-aos={"zoom-in"}>{props.item.description}</p>

          
        
        
        </div>
        
        

    )
}


export default Example


